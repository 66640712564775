.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #ECDA57;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  cursor: pointer;
  color: #00092E;
  -webkit-appearance: none;
  border-radius: 10000px;
  padding: 3px 25px 3px 55px;
  position: relative;
  overflow: hidden;
  background-size: 100% 0;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to bottom, #fff, #fff);
}
.button:before {
  display: none !important;
}
.button:after {
  content: '';
  position: absolute;
  left: 24px;
  top: 9px;
  width: 15px;
  height: 15px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-topRight-blue.svg);
  background-color: transparent !important;
  transition: all 0.4s;
}
.button:hover {
  animation-name: buttonHover;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
.button:hover:after {
  transform: rotate(45deg);
}
@keyframes buttonHover {
  0% {
    background-size: 100% 0;
    background-position: 50% 100%;
  }
  50% {
    background-size: 100% 100%;
    background-position: 50% 100%;
  }
  50.1% {
    background-size: 100% 100%;
    background-position: 50% 0;
  }
  100% {
    background-size: 100% 0;
    background-position: 50% 0;
  }
}
.desk {
  padding-left: 64px;
  padding-right: 64px;
}
@media (max-width: 1024px) {
  .section--one .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 1024px) {
  .footpart {
    width: 100%;
  }
  .footpart--2 {
    margin-left: 0;
    margin-top: 96px;
  }
  .toplink {
    bottom: 96px;
    left: 100%;
    margin-left: -64px;
    top: initial;
    transform: rotate(-90deg);
    transform-origin: 0 100%;
  }
}
.section--one .content {
  margin-bottom: 20px;
}
.section--five .area {
  margin-top: 64px;
  margin-bottom: 64px;
}
.cb-layout5 .section--five .area.farnorth {
  margin-top: 180px;
}
.section--five.section--lightblue .area {
  margin-top: 52px;
  margin-bottom: 52px;
}
.section--six .area {
  margin-top: 84px;
  margin-bottom: 84px;
}
@media (max-width: 1024px) {
  .section--seven .content--1 .area {
    margin-bottom: -168px;
  }
}
.section--two .area .unit + .unit {
  margin-top: 8px;
}
.section--three .area .unit.wide + .unit.wide {
  margin-top: 8px;
}
@media (max-width: 1024px) {
  .section--three .area .unit.slim {
    margin-bottom: -64px;
  }
}
.section--four .area .seam + .seam.slim {
  margin-top: 8px;
}
@media (max-width: 1024px) {
  .section--four .area .seam .part.tiny.text:first-child {
    margin-bottom: 52px;
  }
}
.section--five .area .unit {
  margin-top: 40px;
  margin-bottom: 40px;
}
.section--five .area .seam,
.section--five .area .flat {
  margin-top: 52px;
  margin-bottom: 52px;
}
.section--five .area .edge {
  margin-bottom: 0px;
}
.section--six .area .unit {
  margin-top: 20px;
  margin-bottom: 20px;
}
.section--six .area .seam {
  margin-top: 32px;
  margin-bottom: 32px;
}
.section--six .area .edge {
  margin-bottom: 20px;
}
.section--seven .area .unit .part.tiny.pict + .part.tiny {
  margin-top: 25px;
}
@media (max-width: 1024px) {
  .section--nine .area .pure.slim + .pure.slim + .pure.slim + .pure.slim {
    margin-top: -64px;
  }
}
.section--nine .area .edge {
  margin-bottom: -64px;
}
.unit.fold + .unit.fold {
  margin-top: -117px !important;
}
div.link + div.link {
  margin-top: -4px;
}
.section--four .area {
  width: 100%;
}
.section--four .area > .unit {
  margin-right: 3%;
  margin-left: 3%;
  width: 94%;
}
.section--four .area .cb-album .body,
.section--four .area .head,
.section--four .area .foot,
.section--four .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim {
  width: 44%;
}
.section--four .area > .slim .head,
.section--four .area > .slim .foot,
.section--four .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim .tiny {
  width: 100%;
}
.section--five .area {
  width: 100%;
}
.section--five .area > .unit {
  margin-right: 3%;
  margin-left: 3%;
  width: 94%;
}
.section--five .area .cb-album .body,
.section--five .area .head,
.section--five .area .foot,
.section--five .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--five .area > .slim {
  width: 44%;
}
.section--five .area > .slim .head,
.section--five .area > .slim .foot,
.section--five .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--five .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--five .area > .slim .tiny {
  width: 100%;
}
.section--nine .area {
  width: 100%;
}
.section--nine .area > .unit {
  margin-right: 3%;
  margin-left: 3%;
  width: 94%;
}
.section--nine .area .cb-album .body,
.section--nine .area .head,
.section--nine .area .foot,
.section--nine .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--nine .area > .slim {
  width: 27.33333333%;
}
.section--nine .area > .slim .head,
.section--nine .area > .slim .foot,
.section--nine .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--nine .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--nine .area > .slim .tiny {
  width: 100%;
}
.section--three .area {
  width: 104%;
  margin-left: -2%;
}
.section--four .area {
  width: 106%;
  margin-left: -3%;
}
.section--five .area {
  width: 106%;
  margin-left: -3%;
}
@media (max-width: 1024px) {
  .section--six .area {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
}
.section--nine .area {
  width: 106%;
  margin-left: -3%;
}
.section--three .area .unit {
  margin-left: 2%;
  margin-right: 2%;
}
.section--three .area .unit.wide {
  float: right;
  width: 64%;
}
.section--three .area .unit.wide .body {
  margin-left: -2%;
  width: 104%;
}
.section--three .area .unit.wide .part {
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
}
.section--three .area .unit.wide .part.tiny {
  width: 46%;
}
.section--three .area .unit.slim {
  width: 28%;
}
.section--four .area .seam {
  width: 94%;
}
.section--four .area .seam .body {
  width: 106%;
  margin-left: -3%;
}
.section--four .area .seam .part {
  margin-left: 3%;
  margin-right: 3%;
  width: 94%;
}
.section--four .area .seam .part.tiny {
  width: 44%;
}
@media (max-width: 1024px) {
  .section--four .area .seam .part.tiny.text:first-child {
    margin-left: 3%;
    margin-right: 3%;
    width: 94%;
  }
}
@media (max-width: 1024px) {
  .section--six .area .unit {
    width: 64.6875%;
    align-self: flex-start;
  }
  .section--six .area .unit.slim {
    width: 46.875%;
  }
  .section--six .area .unit.pure:nth-of-type(even),
  .section--six .area .unit.seam:nth-of-type(even) {
    align-self: flex-end;
  }
  .section--six .area .edge {
    width: 100%;
  }
  .section--six .area .edge.slim {
    width: 100%;
  }
}
.section--seven .content--2 .area .unit .part.tiny {
  float: right;
  width: calc(100% - 80px);
}
.section--seven .content--2 .area .unit .part.tiny.pict {
  float: left;
  width: 56px;
}
/*# sourceMappingURL=./screen-medium.css.map */